import React from 'react';
import './App.css';
// import Header from './components/layout/Header';
// import MainContent from './components/common/MainContent';
// import Footer from './components/layout/Footer';
// import { Col, Container, Row } from './utils/Grid';

const App = () => {
  // const [isDarkMode, setIsDarkMode] = useState(true);

  // const toggleTheme = () => {
  //   setIsDarkMode(!isDarkMode);
  // };

  return (
    <div>
      <header className='App-header'>
        <p>Coming Soon.</p>
      </header>
      {/*<Container>*/}
      {/*  <Row>*/}
      {/*    <Col size={3} />*/}
      {/*    <Col size={6}>*/}
      {/*      <Header isDarkMode={isDarkMode} toggleTheme={toggleTheme} />*/}
      {/*    </Col>*/}
      {/*    <Col size={3} />*/}
      {/*  </Row>*/}
      {/*</Container>*/}
      {/*<div className={`app ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>*/}
      {/*  <MainContent />*/}
      {/*  <Footer />*/}
      {/*</div>*/}
    </div>
  );
};

export default App;
